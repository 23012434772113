import React, { useState } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const AgregarCliente = ({ onClose, onClienteAdded }) => {
  const { currentColor } = useStateContext();
  const [userDetails, setUserDetails] = useState({
    nombre: '',
    apellido: '',
    region: '',
    comuna: '',
    direccion: '',
    email: '',
    whatsapp: '',
    notas: '',  // Campo adicional para notas
  });
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const validateFields = () => {
    if (!userDetails.nombre || !userDetails.apellido || !userDetails.email) {
      setError('Los campos de nombre, apellido y email son obligatorios.');
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    if (!validateFields()) return;

    try {
      const token = localStorage.getItem('access_token');  // Obtener el token almacenado
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/clientes/crear/`,  // Ruta de la API para crear un cliente
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Incluir el token en los encabezados
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Cliente creado con éxito:', response.data);
      onClienteAdded();  // Llamar a la función para actualizar la lista de clientes
      onClose();  // Cerrar el modal
    } catch (error) {
      console.error('Error al crear el cliente:', error);
      setError('Ocurrió un error al crear el cliente. Inténtelo de nuevo.');
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className="responsive-div" style={{ backgroundColor: 'white', borderRadius: '12px', padding: '24px', maxWidth: '50%', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Agregar Nuevo Cliente</p>
          <NavButton
            title="Cerrar"
            customFunc={onClose}
            color={currentColor}
            icon={<RiCloseCircleLine />}
          />
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>} {/* Mostrar error si existe */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-700">Nombre</label>
            <input
              type="text"
              name="nombre"
              className="w-full p-2 border rounded"
              value={userDetails.nombre}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Apellido</label>
            <input
              type="text"
              name="apellido"
              className="w-full p-2 border rounded"
              value={userDetails.apellido}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Región</label>
            <input
              type="text"
              name="region"
              className="w-full p-2 border rounded"
              value={userDetails.region}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Comuna</label>
            <input
              type="text"
              name="comuna"
              className="w-full p-2 border rounded"
              value={userDetails.comuna}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Dirección</label>
            <input
              type="text"
              name="direccion"
              className="w-full p-2 border rounded"
              value={userDetails.direccion}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              className="w-full p-2 border rounded"
              value={userDetails.email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">WhatsApp o teléfono</label>
            <input
              type="text"
              name="whatsapp"
              className="w-full p-2 border rounded"
              value={userDetails.whatsapp}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Notas</label>
            <input
              type="text"
              name="notas"
              className="w-full p-2 border rounded"
              value={userDetails.notas}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <button type="button" className="bg-gray-300 p-2 rounded text-gray-700" onClick={onClose}>Cerrar</button>
          <button type="button" className="p-2 rounded text-white" onClick={handleSaveClick} style={{ backgroundColor: '#FFa800' }}>Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default AgregarCliente;
