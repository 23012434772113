import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay'; // Importa el overlay
import { RiCloseCircleLine } from "react-icons/ri";
import { useStateContext } from '../contexts/ContextProvider';
import { FaWaze, FaWhatsapp } from 'react-icons/fa';
import moment from 'moment-timezone';
import imageCompression from 'browser-image-compression'; // Importar la librería de compresión
import html2pdf from 'html2pdf.js';

const DetalleInstalacion = ({ onClose, instalacion}) => {
  const [archivos, setArchivos] = useState([]);
  const [totalM2, setTotalM2] = useState(0); // Define el estado para totalM2
  const [isProcessing, setIsProcessing] = useState(false);
  const [cargandoArchivos, setCargandoArchivos] = useState(false);
  const [mostrarArchivos, setMostrarArchivos] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const { currentColor } = useStateContext();
  const [uploadedFiles, setUploadedFiles] = useState([]); 
  const [orderConfirmed, setOrderConfirmed] = useState(false); 
  const [customerContacted, setCustomerContacted] = useState(false); 
  const [confirmationTime, setConfirmationTime] = useState(''); 
  const [contactTime, setContactTime] = useState(''); 
  const [cliente, setCliente] = useState(null);  
  const [mostrarFormulario, setMostrarFormulario] = useState(false); // Variable para mostrar el formulario
  const [habilitaCerrarInstalacion, setHabilitaCerrarInstalacion] = useState(false);
  const [detalleCotizacion, setDetalleCotizacion] = useState({
    HoraSalida: '',
    ImagenesLink: '',
    Notas: '',
    detalle: [],
    tipo_instalacion: '',
    retiro_laminas: false,
    dificultad: '',
    tipo_propiedad: '',
    monto_retiro_laminas: 0,
    monto_dificultad: 0,
    total_cotizacion: 0,
  });
  const [isEditable, setIsEditable] = useState(true); // Controla si el formulario es editable

  useEffect(() => {
    if (detalleCotizacion.detalle && detalleCotizacion.detalle.length > 0) {
      const total = detalleCotizacion.detalle.reduce((total, item) => {
        return total + (item.m2 || 0); // Suma el valor de m2, si no está definido, suma 0
      }, 0);
      setTotalM2(total); // Actualiza el estado con el valor calculado
    }
  }, [detalleCotizacion.detalle]); // Recalcula totalM2 cuando detalleCotizacion.detalle cambie

  const handleVerArchivos = async () => {
    if (mostrarArchivos) {
      // Si ya se está mostrando, oculta los archivos
      setMostrarArchivos(false);
      return;
    }

    setCargandoArchivos(true);
    try {
      
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/${instalacion.id_instalacion}/listar_imagenes/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      setArchivos(response.data.imagenes || []);
      setMostrarArchivos(true);
    } catch (error) {
      console.error("Error al listar imágenes:", error);
      alert("Hubo un error al cargar los archivos.");
    } finally {
      setCargandoArchivos(false);
    }
  };

  useEffect(() => {
    if (orderConfirmed && customerContacted) {
      setHabilitaCerrarInstalacion(true);
    } else {
      setHabilitaCerrarInstalacion(false);
    }
  }, [orderConfirmed, customerContacted]);

  useEffect(() => {
    const fetchInstalacionData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No se ha encontrado el token de autenticación.');
          return;
        }

        const clienteResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalacion/${instalacion.id_instalacion}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        const data = clienteResponse.data;
        setArchivos(data.imagenes || []);
        setOrderConfirmed(!!data.confirmar_recibida);
        setCustomerContacted(!!data.confirmar_contacto);

        

        setConfirmationTime(data.confirmar_recibida);
        setContactTime(data.confirmar_contacto);
        setDetalleCotizacion((prevState) => ({
          ...prevState,
          HoraSalida: data.hora_salida,
          Notas: data.notas_instalacion,
          detalle: data.orden.cotizacion.detalles || [],
          tipo_instalacion: data.orden.cotizacion.tipo_instalacion,
          retiro_laminas: data.orden.cotizacion.retiro_laminas,
          monto_retiro_laminas: data.orden.cotizacion.monto_retiro_laminas,
          dificultad: data.orden.cotizacion.dificultad,
          tipo_propiedad: data.orden.cotizacion.tipo_propiedad,
          fecha_instalacion: data.orden.fecha_instalacion
        }));
        fetchCoordinates(); 
        setHabilitaCerrarInstalacion(!!(data.confirmar_recibida && data.confirmar_contacto));
        // Información del cliente
        setCliente({
          nombre_completo: data.orden.cotizacion.solicitante.nombre_completo,
          direccion_instalacion: data.orden.cotizacion.direccion_instalacion,
          region: data.orden.cotizacion.solicitante.region,
          comuna: data.orden.cotizacion.solicitante.comuna,
          email: data.orden.cotizacion.solicitante.email,
          whatsapp: data.orden.cotizacion.solicitante.whatsapp
        });

       
       if (data.estado_instalacion === 'realizada') {
        
        setIsEditable(false);
        setMostrarFormulario(true)
      }
        if (!!data.confirmar_recibida) {
          setConfirmationTime(moment(data.confirmar_recibida).format('YYYY-MM-DD HH:mm')); // Mostrar la hora
        }
        if (!!data.confirmar_contacto) {
          setContactTime(moment(data.confirmar_contacto).format('YYYY-MM-DD HH:mm')); // Mostrar la hora
        }
  
        // Mostrar el formulario si confirmar_recibida y confirmar_contacto están presentes

        if (data.confirmar_recibida  && data.confirmar_contacto) {
          // Verifica si HoraSalida tiene un valor válido para mostrar el formulario
          if (data.hora_salida) {
            setMostrarFormulario(true);
          } else {
            setMostrarFormulario(false);
          }
        } else {
          setMostrarFormulario(false);
        }
        
  
      } catch (error) {
        console.error('Error al obtener los detalles de la instalación:', error);
      }
    };
  
    fetchInstalacionData();
  

  }, [instalacion.id_instalacion]);

  // Función para obtener las coordenadas desde LocationIQ
  const fetchCoordinates = async () => {
    const API_KEY = "pk.064831750bf4a0aa7e6a2765c5fe8067"; // Reemplaza con tu API key de LocationIQ
    const address = `${instalacion?.orden?.cotizacion?.direccion_instalacion || ''},${cliente?.region || ''} , Chile`.trim();
    try {
      const response = await axios.get(
        `https://us1.locationiq.com/v1/search.php`,
        {
          params: {
            key: API_KEY,
            q: address,
            format: "json",
          },
        }
      );
      // La API puede devolver múltiples resultados, usamos el primero
      const location = response.data[0];
      setCoordinates({
        lat: location.lat,
        lon: location.lon,
      });
    } catch (error) {
      console.error("Error obteniendo las coordenadas:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetalleCotizacion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Manejo de archivos cargados
  const handleFileUpload = async (event) => {
    const maxFileSize = 5 * 1024 * 1024; // 5 MB
    const allowedTypes = ['image/jpeg', 'image/png'];
    const files = Array.from(event.target.files);
  
    const validFiles = [];
    for (const file of files) {
      if (!allowedTypes.includes(file.type)) {
        alert(`El archivo ${file.name} no es un formato permitido.`);
        continue;
      }
  
      try {
        const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };
        const compressedFile = await imageCompression(file, options);
  
        if (compressedFile.size > maxFileSize) {
          alert(`El archivo comprimido ${file.name} excede el tamaño permitido.`);
          continue;
        }
  
        // Convertir Blob a File
        const finalFile = new File([compressedFile], file.name, { type: file.type });
        validFiles.push(finalFile);
      } catch (error) {
        console.error(`Error al comprimir ${file.name}:`, error);
      }
    }
  
    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleCerrarInstalacion = async () => {
    const { HoraSalida, Notas } = detalleCotizacion;
    setIsProcessing(true);
    if (!orderConfirmed || !customerContacted || !HoraSalida.trim() || uploadedFiles.length === 0) {
      alert('Debes completar todos los campos obligatorios y cargar al menos una imagen.');
      setIsProcessing(false)
      return;
    }
  
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }
  
    // Crear un FormData para incluir las imágenes y los demás datos
    const formData = new FormData();
    formData.append('estado_instalacion', 'realizada');
    formData.append('hora_salida', new Date().toISOString());
    formData.append('notas_instalacion', Notas || 'La instalación se completó exitosamente y se contactó al cliente.');
    
    // Agregar las imágenes al FormData
    uploadedFiles.forEach((file) => {
      formData.append('imagenes[]', file);
    });
    // Enviar la solicitud al backend
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/${instalacion.id_instalacion}/`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      }
    );

    alert('Instalación cerrada con éxito');
    
    // Refrescar la lista de instalaciones desde el padre
    if (onClose) {
      onClose();
    }
    } catch (error) {
      console.error('Error al cerrar la instalación:', error.response || error);
      alert(error.response?.data?.detail || 'Hubo un error al cerrar la instalación.');
    } finally {
      setIsProcessing(false);
    }
  };


  const handleInstalacionLista = async () => {
    setIsProcessing(true);
    try {
      // Fecha y hora actual en formato ISO
      const currentTime = new Date().toISOString();
  
      // Actualizar en el backend el campo hora_salida
      await updateInstalacion('hora_salida', currentTime);
  
      // Actualizar el estado local para reflejar el cambio
      setDetalleCotizacion((prevState) => ({
        ...prevState,
        HoraSalida: currentTime,
      }));
  
      // Mostrar el formulario
      setMostrarFormulario(true);
  
      alert('Instalación marcada como lista y hora de salida registrada.');
    } catch (error) {
      console.error('Error al procesar la instalación:', error);
      alert('Hubo un error.');
    } finally {
      setIsProcessing(false);
    }
  };

  const updateInstalacion = async (field, value) => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }

      const formData = new FormData();
      formData.append(field, value);

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/${instalacion.id_instalacion}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(`Campo ${field} actualizado con éxito`);
    } catch (error) {
      console.error(`Error al actualizar el campo ${field}:`, error);
      alert('Hubo un problema al actualizar la instalación. Inténtalo nuevamente.');
    }
  };

  const generatePDF = async () => {
    try {
      const response = await fetch('/PDFInstalacion.html');
      let templateHTML = await response.text();     
      // Agrupar los detalles
      console.error("hola", detalleCotizacion)
      const groupedDetails = detalleCotizacion.detalle.reduce((acc, item) => {
        const key = `${item.item}-${item.alto}-${item.ancho}-${item.precio}`;
        if (!acc[key]) {
          acc[key] = {
            ...item,
            cantidad: 1,
            total: parseFloat(item.total) || 0, // Inicializa el total
            m2: parseFloat(item.m2) || 0, // Inicializa los m²
          };
        } else {
          acc[key].cantidad += 1;
          acc[key].total += parseFloat(item.total) || 0;
          acc[key].m2 += parseFloat(item.m2) || 0;
        }
        return acc;
      }, {}); 
      const groupedArray = Object.values(groupedDetails);  
      // Calcular montos

      

      templateHTML = templateHTML
      .replace('{{cotizacionId}}', instalacion.id_instalacion)
      .replace('{{fecha}}', moment(detalleCotizacion.fecha_instalacion).format('YYYY-MM-DD'))
      .replace('{{hora}}', moment(detalleCotizacion.fecha_instalacion).format('HH:mm'))
      .replace('{{tipoInstalacion}}', detalleCotizacion.tipo_instalacion )
      .replace('{{clienteNombre}}', cliente.nombre_completo)
      .replace('{{clienteWhatsApp}}', cliente.whatsapp)
      .replace('{{clienteEmail}}', cliente.email)
      .replace('{{totalM2}}', totalM2)
      .replace('{{direccionInstalacion}}', detalleCotizacion.direccion_instalacion)
      .replace('{{clienteComuna}}', ((cliente?.region || '') + ' - ' + (detalleCotizacion?.orden?.cotizacion?.direccion_instalacion || '')));

            // Generar las filas agrupadas
            let detalleItems = '';
            groupedArray.forEach((item, index) => {
              detalleItems += `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.item}</td>
                  <td>${item.cantidad}</td>
                  <td>${item.alto || 'N/A'}</td>
                  <td>${item.ancho || 'N/A'}</td>
                  <td>${(item.m2).toFixed(2)}</td>
                </tr>
              `;
            });
            // Calcula el número de la siguiente fila

            templateHTML = templateHTML.replace('{{detalleItems}}', detalleItems);
      
            const element = document.createElement('div');
            element.innerHTML = templateHTML;
      
            const opt = {
              filename: `Instalacion_${instalacion.id_instalacion}.pdf`,
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 3 },
              jsPDF: { unit: 'mm', format: [320, 210], orientation: 'portrait' },
            };
      
            const pdfBase64 = await html2pdf().from(element).set(opt).outputPdf('datauristring');
            return pdfBase64.split(',')[1]; 
          } catch (error) {
            console.error('Error al generar el PDF:', error);
            alert('Ocurrió un error al generar el PDF. Por favor, inténtalo de nuevo.');
          }
        };

  const handleDownloadPDF = async () => {
    const pdfBase64 = await generatePDF();
    if (pdfBase64) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.download = `Instalacion_${instalacion.id_instalacion}.pdf`;
      link.click();
    }
  };
  const handleOrderConfirmation = async (e) => {
    const isChecked = e.target.checked;
    setOrderConfirmed(isChecked); // Actualiza el estado
    const currentTime = isChecked ? new Date().toISOString() : '';
    setConfirmationTime(currentTime);

    // Actualizar en la API
    await updateInstalacion('confirmar_recibida', currentTime);
  };

  const handleCustomerContacted = async (e) => {
    const isChecked = e.target.checked;
    setCustomerContacted(isChecked); // Actualiza el estado
    const currentTime = isChecked ? new Date().toISOString() : '';
    setContactTime(currentTime);

    // Actualizar en la API
    await updateInstalacion('confirmar_contacto', currentTime);
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Instalación</p>
            <button
              type="button"
              onClick={onClose}
              style={{ color: currentColor }}
              className="relative text-3xl rounded-full p-4 hover:bg-light-gray"
            >
              <RiCloseCircleLine />
            </button>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Cliente:</strong></p>
              {cliente ? (
                <>
                  <p>{cliente.nombre_completo}</p>
                  <p>{cliente.email}</p>
                  <p>{cliente.whatsapp}</p>
                </>
              ) : (
                <p>Cargando información del cliente...</p>
              )}
              <p>
                <strong>Dirección: </strong> 
                {(cliente?.region || '') + ' - ' + (instalacion?.orden?.cotizacion?.direccion_instalacion || '')}
              </p>
              {coordinates ? (
              <div className="flex space-x-4 mt-2">
                <a
                  href={`https://waze.com/ul?ll=${coordinates.lat},${coordinates.lon}&navigate=yes`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
                >
                  <FaWaze className="mr-2" /> Waze
                </a>

                {cliente && cliente.whatsapp && (
                  <a
                    href={`https://wa.me/${cliente.whatsapp.replace(/[^0-9]/g, '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                  >
                    <FaWhatsapp className="mr-2" /> WhatsApp
                  </a>
                )}
              </div>
            ) : (
              <p>Cargando coordenadas...</p>
            )}
              
            </div>
            <div className="right-info">
              <p><strong>Nº Instalación:</strong> {instalacion.id_instalacion}</p>
              <p><strong>Fecha Instalación: {moment(detalleCotizacion.fecha_instalacion).format('YYYY-MM-DD')}</strong></p>
              <p><strong>Hora Instalación: {moment(detalleCotizacion.fecha_instalacion).format('HH:mm:ss')}</strong> </p>
              <p><strong>Estado:</strong> {instalacion.estado_instalacion}</p>
              <p><strong>Tipo Servicio:</strong> {detalleCotizacion.tipo_instalacion  || ''}</p>
              <p><strong>Retiro Lámina:</strong> {detalleCotizacion.retiro_laminas ? 'Sí' : 'No'}</p>
              <p><strong>Dificultad:</strong> {detalleCotizacion.dificultad || ''}</p>
              <p><strong>Tipo Propiedad:</strong> {detalleCotizacion.tipo_propiedad || ''}</p>
            </div>
          </div>

          {/* Confirmación de orden de instalación */}
          <div className="mt-4 mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={orderConfirmed}
                onChange={handleOrderConfirmation}
                disabled={orderConfirmed}
              />
              <span className="ml-2 text-gray-700">Confirmo que he recibido y leído la orden de instalación</span>
            </label>
            {confirmationTime && (
              <p className="mt-2 text-green-600">{`Confirmada: ${confirmationTime}`}</p>
            )}
          </div>

          {/* Confirmación de contacto con el cliente */}
          <div className="mt-4 mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={customerContacted}
                onChange={handleCustomerContacted}
                disabled={customerContacted}
              />
              <span className="ml-2 text-gray-700">Confirmo que el cliente ha sido contactado para confirmar la fecha de instalación</span>
            </label>
            {contactTime && (
              <p className="mt-2 text-green-600">{`Contacto: ${contactTime}`}</p>
            )}
          </div>

          <div>
          <button onClick={handleDownloadPDF} type="button" className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200">
            Descargar PDF
          </button>
          <a
              href={
                cliente
                  ? `https://web.whatsapp.com/send?phone=56974235947&text=${encodeURIComponent(
                      `Estimado Lukitas,

Adjunto encontrará la Instalación N° ${instalacion.id_instalacion}

Detalles de la cotización:
- Metros cuadrados (m²): ${totalM2}
- Tipo de servicio: ${detalleCotizacion.tipo_instalacion}
- Fecha Instalacion: ${moment(detalleCotizacion.fecha_instalacion).format('DD-MM-YYYY')}
- Hora Instalación: ${moment(detalleCotizacion.fecha_instalacion).format('HH:mm')}

Ante cualquier consulta adicional, quedamos atentos para responder a la brevedad.

Saludos cordiales,
Equipo FilmSolar`
                    )}`
                  : '#'
              }
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-flex items-center bg-green-500 text-white p-2 rounded-lg ${
                cliente ? 'hover:bg-green-600' : 'cursor-not-allowed'
              } transition duration-200 ml-2`}
              onClick={(e) => {
                if (!cliente) {
                  e.preventDefault();
                  alert('Los datos del cliente aún no están disponibles.');
                }
              }}
              >
              <FaWhatsapp className="mr-2" /> Enviar por Wsp
              </a>            
        </div>

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Item</th>
                <th>Cantidad</th>
                <th>Alto</th>
                <th>Ancho</th>
                <th>M²</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                // Agrupa los detalles por columnas clave
                const groupedDetails = detalleCotizacion.detalle
                ? detalleCotizacion.detalle.reduce((acc, item) => {
                    const key = `${item.item}-${item.alto}-${item.ancho}-${item.m2}-${item.total}`;
                    if (!acc[key]) {
                      acc[key] = { ...item, cantidad: 1 };
                    } else {
                      acc[key].cantidad += 1;
                    }
                    return acc;
                  }, {})
                : {};
              
              const groupedArray = Object.values(groupedDetails);

              const rows = groupedArray.map((item, index) => {
                const m2 = parseFloat(item.m2) || 0; // Asegurarse de que item.m2 sea numérico
                const cantidad = item.cantidad || 0; // Asegurarse de que cantidad sea numérico
                const totalM2 = m2 * cantidad; // Calcular el total de m²              
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.item}</td>
                      <td>{item.cantidad}</td>
                      <td>{item.alto || 'N/A'}</td>
                      <td>{item.ancho || 'N/A'}</td>
                      <td>{totalM2 ? `${totalM2.toFixed(2)} m²` : 'N/A'}</td>
                    </tr>
                  );
                });

                // Agregar filas adicionales
                let extraRowIndex = groupedArray.length;
                
                if (detalleCotizacion.retiro_laminas) {
                  rows.push(
                    <tr key="retiro_laminas">
                      <td>{++extraRowIndex}</td>
                      <td colSpan="5">Retiro de láminas ({(detalleCotizacion.monto_retiro_laminas / 6990).toFixed(2)} m²)</td>
                    </tr>
                  );
                }

                return rows;
              })()}
            </tbody>
          </table>
          {mostrarFormulario ? (
            <>
              <div className="mt-6">
                <h3 className="font-semibold text-lg mb-4">Registros de instalación</h3>
                <div className="mb-4">
                  <label className="block text-gray-700">Hora de salida:</label>
                  <p className="w-full p-2 border rounded bg-gray-100">{moment(detalleCotizacion.HoraSalida).format('YYYY-MM-DD HH:mm') || 'Sin hora registrada'}</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{isEditable ? 'Cargar imágenes/videos: ' : 'Imágenes/videos cargados:'}</label>
                    {isEditable ? (
                    <>
                      <input
                        type="file"
                        className="w-full p-2 border rounded"
                        multiple
                        onChange={handleFileUpload}
                      />
                      <ul className="mt-4">
                        {uploadedFiles.map((file, index) => (
                          <li key={index} className="text-gray-700">{file.name}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <div>
      
                    <button
                      onClick={handleVerArchivos}
                      className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
                    >
                      {mostrarArchivos ? "Ocultar Archivos" : "Ver Archivos"}
                    </button>
              
                    {cargandoArchivos && <p>Cargando archivos...</p>}
              
                    {mostrarArchivos && (
                      <ul>
                        {archivos.length > 0 ? (
                          archivos.map((archivo, index) => (
                            <li key={index}>
                              <a
                                href={archivo.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                {archivo.nombre || `Archivo ${index + 1}`}
                              </a>
                            </li>
                          ))
                        ) : (
                          <p>No hay archivos disponibles.</p>
                        )}
                      </ul>
                    )}
                  </div>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Notas:</label>
                  {isEditable ? (
                    <textarea
                      name="Notas"
                      className="w-full p-2 border rounded"
                      rows="4"
                      placeholder="Notas adicionales sobre la instalación..."
                      value={detalleCotizacion.Notas}
                      onChange={handleInputChange}
                    ></textarea>
                  ) : (
                    <p className="w-full p-2 border rounded bg-gray-100">{detalleCotizacion.Notas || 'Sin notas registradas'}</p>
                  )}
                </div>
              </div>
              {isProcessing && <LoadingOverlay message="Cerrando instalación, por favor espere..." />}
              <div className="flex justify-between mt-4">
              {isEditable && (
                <button
                  type="button"
                  className={`bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 ${
                    isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleCerrarInstalacion}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <>
                      <span className="loader mr-2"></span> {/* Spinner opcional */}
                      Procesando...
                    </>
                  ) : (
                    'Cerrar instalación'
                  )}
                </button>
              )}
            </div>
            </>
          ) : (
                <>
                  {habilitaCerrarInstalacion ? (
                    <button
                      type="button"
                      className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
                      onClick={handleInstalacionLista}
                      disabled={isProcessing}
                    >
                      {isProcessing ? 'Procesando...' : 'Instalación Lista'}
                    </button>
                  ) : (
                    <p className="text-gray-700 p-2 rounded bg-yellow-100">
                      Recuerda checkear confirmar recepción de orden y notificación al cliente
                    </p>
                  )}
                </>
          )}
        </div>
      </div>
    </>
  );
};

export default DetalleInstalacion;
