import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'; // Para hacer la petición
import html2pdf from 'html2pdf.js';
import '../DetalleCotizacion.css'; // Importa los estilos desde el archivo CSS
import moment from 'moment'; // Para manejar fechas
import { FaWhatsapp } from "react-icons/fa"; // Asegúrate de importar el ícono

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleOrden = ({ cotizacionId, onClose, ordenId, refreshOrdenes }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedInstalador, setSelectedInstalador] = useState('');
  const [fechaInstalacion, setFechaInstalacion] = useState(null);
  const [rangoHoras, setRangoHoras] = useState('');
  const [numeroSeguimiento, setNumeroSeguimiento] = useState('');
  const [vehiculos, setVehiculos] = useState([]);
  const [fechaCotizacion, setFechaCotizacion] = useState('');
  const [horariosDisponibles, setHorariosDisponibles] = useState([
    { value: '9-12', label: '9:00 AM - 12:00 PM', disponible: true },
    { value: '12-15', label: '12:00 PM - 3:00 PM', disponible: true },
    { value: '15-18', label: '3:00 PM - 6:00 PM', disponible: true },
  ]);
  const { currentColor } = useStateContext();
  const [cotizacion, setCotizacion] = useState(null);
  const [orden, setOrden] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalM2, setTotalM2] = useState(0);

  const generatePDF = async () => {
    try {
      const response = await fetch('/PDFOrden.html');
      let templateHTML = await response.text();     
      // Agrupar los detalles
      const groupedDetails = cotizacion.detalles.reduce((acc, item) => {
        const key = `${item.item}-${item.alto}-${item.ancho}-${item.precio}`;
        if (!acc[key]) {
          acc[key] = {
            ...item,
            cantidad: 1,
            total: parseFloat(item.total) || 0, // Inicializa el total
            m2: parseFloat(item.m2) || 0, // Inicializa los m²
          };
        } else {
          acc[key].cantidad += 1;
          acc[key].total += parseFloat(item.total) || 0;
          acc[key].m2 += parseFloat(item.m2) || 0;
        }
        return acc;
      }, {}); 
      const groupedArray = Object.values(groupedDetails);  
      // Calcular montos
      const totalLaminas = cotizacion.detalles.reduce((total, item) => total + (parseFloat(item.total) || 0), 0);
      const montoDificultad = parseFloat(cotizacion.monto_dificultad) || 0;
      const montoRetiroLaminas = parseFloat(cotizacion.monto_retiro_laminas) || 0;
      const montoDistancia = parseFloat(cotizacion.monto_distancia) || 0;
      const montoNeto1 = totalLaminas + montoDificultad + montoRetiroLaminas + montoDistancia;
      const descuento = cotizacion.descuento > 0 ? montoNeto1 * (cotizacion.descuento / 100) : 0;
      const montoNeto = Math.round(montoNeto1 - descuento);
      const iva = Math.round(montoNeto * 0.19);
   

      templateHTML = templateHTML
      .replace('{{nombrePago}}', process.env.REACT_APP_NOMBRE_PAGO)
      .replace('{{rutPago}}', process.env.REACT_APP_RUT_PAGO)
      .replace('{{bancoPago}}', process.env.REACT_APP_BANCO_PAGO)
      .replace('{{tipoCuentaPago}}', process.env.REACT_APP_TIPO_CUENTA_PAGO)
      .replace('{{numeroCuentaPago}}', process.env.REACT_APP_NUMERO_CUENTA_PAGO)
      .replace('{{mailCuentaPago}}', process.env.REACT_APP_MAIL_CUENTA_PAGO)
      .replace('{{montoPorM2}}', totalLaminas.toLocaleString('es-CL'))
      .replace('{{dificultad}}', cotizacion.monto_dificultad.toLocaleString('es-CL'))
      .replace('{{retiroLaminas}}', cotizacion.monto_retiro_laminas.toLocaleString('es-CL'))
      .replace('{{distancia}}', cotizacion.monto_distancia.toLocaleString('es-CL'))
      .replace('{{montoNeto}}', montoNeto.toLocaleString('es-CL'))
      .replace('{{iva}}', iva.toLocaleString('es-CL'))
      .replace('{{descuento}}', descuento.toLocaleString('es-CL'))
      .replace('{{totalFinal}}', cotizacion.total_cotizacion.toLocaleString('es-CL'))
      .replace('{{cotizacionId}}', ordenId)
      .replace('{{fecha}}', fechaCotizacion)
      .replace('{{tipoInstalacion}}', cotizacion.tipo_instalacion)
      .replace('{{clienteNombre}}', cliente.nombre)
      .replace('{{clienteApellido}}', cliente.apellido)
      .replace('{{clienteWhatsApp}}', cliente.whatsapp)
      .replace('{{clienteEmail}}', cliente.email)
      .replace('{{direccionInstalacion}}', cotizacion.direccion_instalacion)
      .replace('{{clienteComuna}}', cliente.comuna);

            // Generar las filas agrupadas
            let detalleItems = '';
            groupedArray.forEach((item, index) => {
              detalleItems += `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.item}</td>
                  <td>${item.precio.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  <td>${item.cantidad}</td>
                  <td>${item.alto || 'N/A'}</td>
                  <td>${item.ancho || 'N/A'}</td>
                  <td>${(item.m2).toFixed(2)}</td>
                  <td>${item.total.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                </tr>
              `;
            });
            // Calcula el número de la siguiente fila
            let nextRowNumber = cotizacion.detalles.length + 1;
              // Agregar filas adicionales de Dificultad, Retiro Láminas y Distancia
              if (cotizacion.monto_dificultad) {
                detalleItems += `
                  <tr>
                    <td>${nextRowNumber++}</td>
                    <td colspan="6"><strong>Dificultad</strong></td>
                    <td>$${parseFloat(cotizacion.monto_dificultad).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                `;
              }
              if (cotizacion.monto_retiro_laminas) {
                detalleItems += `
                  <tr>
                    <td>${nextRowNumber++}</td>
                    <td colspan="6"><strong>Retiro Láminas</strong></td>
                    <td>$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                `;
              }
          
              if (cotizacion.monto_distancia) {
                detalleItems += `
                  <tr>
                    <td>${nextRowNumber++}</td>
                    <td colspan="6"><strong>Distancia</strong></td>
                    <td>$${parseFloat(cotizacion.monto_distancia).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                `;
              }
              if (cotizacion.descuento > 0) {
                detalleItems += `
                  <tr>
                    <td>${nextRowNumber++}</td>
                    <td colSpan="6"><strong>Descuento: ${parseFloat(cotizacion.descuento).toLocaleString()}%</strong></td>
                    <td>-${((descuento)).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                `;
              }
            templateHTML = templateHTML.replace('{{detalleItems}}', detalleItems);
      
            const element = document.createElement('div');
            element.innerHTML = templateHTML;
      
            const opt = {
              filename: `cotizacion_${cotizacionId}.pdf`,
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 3 },
              jsPDF: { unit: 'mm', format: [320, 210], orientation: 'portrait' },
            };
      
            const pdfBase64 = await html2pdf().from(element).set(opt).outputPdf('datauristring');
            return pdfBase64.split(',')[1]; 
          } catch (error) {
            console.error('Error al generar el PDF:', error);
            alert('Ocurrió un error al generar el PDF. Por favor, inténtalo de nuevo.');
          }
        };

  const handleDownloadPDF = async () => {
    const pdfBase64 = await generatePDF();
    if (pdfBase64) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.download = `orden_${ordenId}.pdf`;
      link.click();
    }
  };


  const handleAsignarClick = () => {
    setIsEditing(true); // Activar el modo de edición
  
    // Asegurarse de que el scroll ocurra después de renderizar los nuevos elementos
    setTimeout(() => {
      const container = document.querySelector('.responsive-div'); // Asegúrate de seleccionar el contenedor adecuado
      if (container) {
        container.scrollTo({
          top: container.scrollHeight, // Desplaza hasta el final del contenedor
          behavior: 'smooth' // Hace que el desplazamiento sea suave
        });
      }
    }, 300); // Aumenta el tiempo de espera si es necesario
  };

  // Función para obtener eventos y filtrar los horarios disponibles
  const fetchEventosYFiltrarHorarios = async (vehiculo, fecha) => {
    try {
      const token = localStorage.getItem('access_token');  // Obtén el token de localStorage
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/events/events`, {
        params: {
          vehicle: vehiculo,
          fecha: fecha,
        },
        headers: {
          Authorization: `Bearer ${token}`,  // Asegúrate de incluir el token en los headers
        },
      });
  
      const eventos = response.data;
      console.error("fecha: ",response)
      // Filtrar los horarios ocupados
      const horariosOcupados = horariosDisponibles.map(horario => {
        const horarioOcupado = eventos.some(evento => {
          const eventDate = new Date(evento.start_time); // Convertimos el start_time a un objeto Date
          
          // Obtener la hora de Santiago (restamos 3 horas para ajustar de GMT 0 a GMT -3)
          const localStartHour = eventDate.getUTCHours() - 3;
      
          // Si la hora es negativa, ajustamos a 24 horas (por ejemplo, para las horas de la medianoche)
          const startHour = localStartHour < 0 ? localStartHour + 24 : localStartHour;
      
          console.error("hora inicio ajustada es: ", startHour)
      
          if (horario.value === '9-12' && startHour >= 9 && startHour < 12) {
            return true;
          }
          if (horario.value === '12-15' && startHour >= 12 && startHour < 15) {
            return true;
          }
          if (horario.value === '15-18' && startHour >= 15 && startHour < 18) {
            return true;
          }
          return false;
        });
        return { ...horario, disponible: !horarioOcupado };
      });
  
      setHorariosDisponibles(horariosOcupados);
    } catch (error) {
      console.error('Error al obtener eventos:', error);
    }
  };
    // Cuando cambien la fecha o el vehículo, buscar los eventos correspondientes
    useEffect(() => {
      if (selectedInstalador && fechaInstalacion) {
        fetchEventosYFiltrarHorarios(selectedInstalador, fechaInstalacion.toISOString().split('T')[0]);
      }
    }, [selectedInstalador, fechaInstalacion]);

    const handleGuardarClick = async () => {
      try {
        const token = localStorage.getItem('access_token');
    
        // Verificar si el estado de la orden es "pendiente_asignar"
        if (orden.estado === 'pendiente_asignar') {
          // Calcular start_time y end_time
          const [startHour, endHour] = rangoHoras.split('-').map(Number);
          const startDateTime = new Date(fechaInstalacion);
          startDateTime.setUTCHours(startHour + 3); // Ajustar a GMT -3 para Santiago
          const endDateTime = new Date(startDateTime);
          endDateTime.setUTCHours(startDateTime.getUTCHours() + 3); // Añadir 3 horas para el end_time
          // Obtener la comuna abreviada a 4 caracteres (valpo para Valparaíso)
          const abreviacionComuna = cliente.comuna
            ? cliente.comuna.slice(0, 4).toUpperCase()
            : 'N/A'; 
          // Determinar el subject según tipo_instalacion
          let subject = '';
          if (cotizacion.tipo_instalacion === 'instalacion') {
            subject = `INST${abreviacionComuna}`;
          } else if (cotizacion.tipo_instalacion === 'Visita') {
            subject = `VTEC${abreviacionComuna}`;
          }        
          // Extraer nombres únicos de las láminas solares
          const nombresLaminas = [...new Set(cotizacion.detalles.map(item => item.item))];
          const nombresLaminasStr = nombresLaminas.join(', ');      
          // Crear el payload para el evento
          const eventPayload = {
            subject: subject,
            location: `${cotizacion.direccion_instalacion} - ${cliente.comuna}`,
            start_time: startDateTime.toISOString(),  // Convertir a ISO string con la zona horaria ajustada
            end_time: endDateTime.toISOString(),
            category_color: "#ff0000",
            description: `Láminas: ${nombresLaminasStr} - M2: ${totalM2.toFixed(2)} - OV: ${orden.id_orden}`,
            vehicle: selectedInstalador,
            order: orden.id_orden  // ID de la orden
          };
    
          // Crear el evento
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/events/events/`, eventPayload, {
            headers: { Authorization: `Bearer ${token}` }
          });

          // Crear el payload para la nueva instalación
          const instalacionPayload = {
            orden: orden.id_orden,
            estado_instalacion: "pendiente",
            confirmar_recibida: null,
            confirmar_contacto: null,
            hora_salida: null,
            imagenes: [],
            notas_instalacion: null
          };

          // Crear la instalación
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/instalacion/instalaciones/`, instalacionPayload, {
            headers: { Authorization: `Bearer ${token}` }
          });     
    
          // Crear el payload para actualizar la orden de venta
          const ordenPayload = {
            estado: "asignado",
            fecha_instalacion: startDateTime.toISOString(), // Usar el mismo startDateTime del evento
            instalador: selectedInstalador
          };
    
          // Actualizar la orden de venta
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/${orden.id_orden}/`, ordenPayload, {
            headers: { Authorization: `Bearer ${token}` }
          });
    
          // Luego de guardar el evento y actualizar la orden, desactiva el modo de edición
          setIsEditing(false);
          refreshOrdenes();  // Actualiza las órdenes
        } else if (orden.estado === 'por_enviar') {
          const fechaEnvio = new Date().toISOString(); // Fecha actual en formato ISO
          // Crear el payload para actualizar la orden de venta cuando se está en "por_enviar"
          const ordenPayload = {
            estado: "enviado",
            numero_seguimiento: numeroSeguimiento,  // Número de seguimiento ingresado por el usuario
            fecha_instalacion: fechaEnvio,  // Fecha del momento de envío
            instalador: selectedInstalador
          };
          // Actualizar la orden de venta
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/${orden.id_orden}/`, ordenPayload, {
            headers: { Authorization: `Bearer ${token}` }
          });
    
          // Luego de actualizar la orden, desactiva el modo de edición
          setIsEditing(false);
          refreshOrdenes();  // Actualiza las órdenes
        } else {
          console.log('El estado de la orden no es "pendiente_asignar" ni "por_enviar". No se realizan acciones.');
        }

        // Refrescar la grilla de órdenes
        if (refreshOrdenes) {
          await refreshOrdenes();
        }

        // Cerrar el modal
        if (onClose) {
          onClose();
        }        
      } catch (error) {
        console.error('Error al guardar el evento o actualizar la orden:', error);
        // Manejar el error, por ejemplo, mostrando un mensaje al usuario
      }
    };
  

  useEffect(() => {
    let isMounted = true; // Para controlar si el componente sigue montado

    // Función para obtener los vehículos desde la API
    const fetchVehiculos = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/vehiculos/`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (isMounted) {
          setVehiculos(response.data);
        }
      } catch (error) {
        console.error('Error al cargar los vehículos:', error);
        if (isMounted) {
          setError('No se pudo cargar la lista de vehículos.');
        }
      }
    };

    fetchVehiculos(); // Llamar a la función cuando se monte el componente

    return () => {
      isMounted = false; // Cleanup para prevenir memory leak
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // Para controlar si el componente sigue montado

    // Función para obtener los detalles de la orden por su ID
    const fetchOrden = async () => {
      
      try {
        const token = localStorage.getItem('access_token');
        const responseOrden = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/${ordenId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (isMounted) {
          setOrden(responseOrden.data);

          // Obtener la cotización asociada
          const cotizacionId = responseOrden.data.cotizacion;
          const responseCotizacion = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setCotizacion(responseCotizacion.data);
          // Formatear la fecha y guardarla
          const formattedDate = new Date(responseCotizacion.data.fecha_cotizacion).toLocaleDateString('es-CL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          setFechaCotizacion(formattedDate);

                  // Calcular el total de metros cuadrados
          const totalMetros = responseCotizacion.data.detalles.reduce((total, item) => {
            return total + (parseFloat(item.m2) || 0);
          }, 0);
          setTotalM2(totalMetros);

          // Hacer el segundo llamado a la API para obtener los detalles del cliente usando solicitante
          
          if (responseCotizacion.data.solicitante) {
            const clienteResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clientes/clientes/${responseCotizacion.data.solicitante}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            setCliente(clienteResponse.data); 
          }
          
          setLoading(false);
        }
      } catch (error) {
        console.error('Error al cargar los detalles de la orden, cotización o cliente:', error);
        if (isMounted) {
          setError('No se pudo cargar la orden, la cotización o el cliente.');
          setLoading(false);
        }
      }
    };

    fetchOrden(); // Llamar a la función cuando se monte el componente

    return () => {
      isMounted = false; // Cleanup para prevenir memory leak
    };
  }, [ordenId]);

  const handleChangeEstado = async (nuevoEstado) => {
    try {
      const token = localStorage.getItem('access_token');
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}/cambiar-estado/`, 
      { estado: nuevoEstado }, 
      {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Actualizar el estado localmente para reflejar el cambio sin necesidad de recargar
      setCotizacion((prevCotizacion) => ({
        ...prevCotizacion,
        estado: nuevoEstado
      }));

      // Refresca las órdenes en la grilla principal
      if (refreshOrdenes) {
        refreshOrdenes();
      }

      // Cierra el modal automáticamente
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error(`Error al cambiar el estado de la cotización a ${nuevoEstado}:`, error);
    }
  };

  const handleAprobarClick = () => {
    const confirmar = window.confirm('¿Estás seguro de que deseas aprobar esta cotización?');
    if (confirmar) {
      handleChangeEstado('aprobado');
    }
  };

  if (loading) {
    return <div>Cargando los detalles de la Orden de venta...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!cotizacion) {
    return <div>No se encontraron datos de la Orden.</div>;
  }

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Orden de venta</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"  // Aumenta el tamaño del ícono
              padding="p-4"  // Aumenta el padding del botón
            />
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">ORDEN VENTA</h1>
            <div className="yellow-bar-right"></div>
          </div>

          {/* Información del cliente */}
          {cliente && (
            <div className="cliente-info">
              <div className="left-info">
                <p><strong>Cliente:</strong> </p>
                <p>{cliente.nombre} {cliente.apellido}</p>
                <p>{cliente.whatsapp}</p>
                <p>{cliente.email}</p>
                <p>{cotizacion.direccion_instalacion}</p>
                <p>{cliente.comuna}</p>
                <p><strong>N° Cotización: </strong>{cotizacion.id_cotizacion}</p>
                <p><strong>N° Venta: </strong>{orden.id_orden}</p>
              </div>
              <div className="right-info">
                <p><strong>Estado:</strong> {orden.estado}</p>
                {orden.fecha_instalacion && (
                  <p><strong>Fecha Instalación:</strong> {moment(orden.fecha_instalacion).format('DD/MM/YYYY')}</p>
                )}
                {orden.estado !== 'enviado' && (
                  <p><strong>Instalador:</strong> {orden.instalador ? orden.instalador : 'Pendiente Asignar'}</p>
                )}
                <p><strong>Tipo Servicio:</strong> {cotizacion.tipo_instalacion}</p>
                <p><strong>Retiro Lamina:</strong> {cotizacion.retiro_laminas ? "Sí" : "No"}</p>
                <p><strong>Dificultad:</strong> {cotizacion.dificultad}</p>
                <p><strong>Tipo Propiedad:</strong> {cotizacion.tipo_propiedad}</p>
                {orden.numero_seguimiento && (
                  <p><strong>Seguimiento:</strong> {orden.numero_seguimiento}</p>
                )}
              </div>
            </div>
          )}

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Item</th>
                <th>Precio (m²)</th>
                <th>Cant.</th>
                <th>Alto</th>
                <th>Ancho</th>
                <th>M²</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                // Agrupa los detalles por columnas clave
                const groupedDetails = cotizacion.detalles.reduce((acc, item) => {
                  const key = `${item.item}-${item.alto}-${item.ancho}-${item.m2}-${item.total}`;
                  if (!acc[key]) {
                    acc[key] = { ...item, cantidad: 1 };
                  } else {
                    acc[key].cantidad += 1;
                  }
                  return acc;
                }, {});
                console.error("Los detalles: ",groupedDetails)

                // Convierte el objeto agrupado en un array para iterar
                const groupedArray = Object.values(groupedDetails);

                // Renderizar filas agrupadas
                const rows = groupedArray.map((item, index) => {
                  const totalM2 = (parseFloat(item.m2) || 0) * item.cantidad; // Total m² multiplicado por cantidad
                  const totalPrecio = (parseFloat(item.total) || 0) * item.cantidad; // Total precio multiplicado por cantidad

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.item}</td>
                      <td>{(item.precio / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                      <td>{item.cantidad}</td>
                      <td>{item.alto || 'N/A'}</td>
                      <td>{item.ancho || 'N/A'}</td>
                      <td>{totalM2 ? `${totalM2.toFixed(2)}` : 'N/A'}</td>
                      <td>{totalPrecio ? `$${totalPrecio.toLocaleString()}` : 'N/A'}</td>
                    </tr>
                  );
                });

                // Agregar filas adicionales
                let extraRowIndex = groupedArray.length;

                if (cotizacion.retiro_laminas) {
                  rows.push(
                    <tr key="retiro_laminas">
                      <td>{++extraRowIndex}</td>
                      <td colSpan="6">Retiro de láminas</td>
                      <td>{`$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString()}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.cobro_distancia) {
                  rows.push(
                    <tr key="cobro_distancia">
                      <td>{++extraRowIndex}</td>
                      <td colSpan="6">Monto por distancia</td>
                      <td>{`$${parseFloat(cotizacion.monto_distancia).toLocaleString()}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.dificultad !== 'normal') {
                  rows.push(
                    <tr key="dificultad">
                      <td>{++extraRowIndex}</td>
                      <td colSpan="6">{`Dificultad: ${cotizacion.dificultad}`}</td>
                      <td>{`$${parseFloat(cotizacion.monto_dificultad).toLocaleString()}`}</td>
                    </tr>
                  );
                }

                if (cotizacion.descuento > 0) {
                  // Calcular monto base (sin IVA ni descuento)
                  const montoBase = cotizacion.total_cotizacion / 1.19;
                  // Calcular monto antes del descuento
                  const montoAntesDescuento = montoBase / (1 - (cotizacion.descuento / 100));
                  // Calcular el descuento
                  const descuento = montoAntesDescuento - montoBase;
                  rows.push(
                    <tr key="descuento">
                      <td>{++extraRowIndex}</td>
                      <td colSpan="6">Descuento: {parseFloat(cotizacion.descuento).toLocaleString()}%</td>
                      <td>-{descuento.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                    </tr>
                  );
                }
                
                // Agregar fila para el IVA
                console.error(cotizacion.total_cotizacion)
                const ivaTotal = (cotizacion.total_cotizacion - (cotizacion.total_cotizacion / 1.19)); // Calcula el IVA total
                rows.push(
                  <tr key="iva">
                    <td>{++extraRowIndex}</td>
                    <td colSpan="6">IVA</td>
                    <td>{(ivaTotal / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</td>
                  </tr>
                );
                return rows;
              })()}
            </tbody>
          </table> 

          {/* Monto total debajo de la tabla */}
          <div className="flex justify-end mt-4">
            <p className="font-semibold text-lg">Total: {(cotizacion.total_cotizacion / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
          </div>

          {/* Monto total de m² debajo de la tabla */}
          <div className="flex justify-end mt-2">
            <p className="font-semibold text-lg">Total m²: {totalM2.toFixed(2)} m²</p>
          </div>
          <div className="flex justify-between items-center mt-4">
            {/* Botón Descargar PDF y los demás botones en la misma fila */}
            {!isEditing && (
              <div className="flex items-center">
            <button onClick={handleDownloadPDF} type="button" className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200">
            Descargar PDF
          </button>
         
            <a
              href={
                cliente
                  ? `https://web.whatsapp.com/send?phone=${cliente.whatsapp.replace(/[^0-9]/g, '')}&text=${encodeURIComponent(
                      `Estimado Sr. ${cliente.nombre} ${cliente.apellido},

              Adjunto encontrará la Orden N°${orden.id_orden}, junto con la ficha técnica del film, el cual cumple con todas las características solicitadas.

              Detalles de la cotización:
              - Metros cuadrados (m²): ${totalM2.toFixed(2)}
              - Total: $${cotizacion.total_cotizacion.toLocaleString('es-CL')}
              - Tipo de servicio: ${cotizacion.tipo_instalacion}

              Ante cualquier consulta adicional, quedamos atentos para responder a la brevedad.

              Saludos cordiales,
              Equipo FilmSolar`
                    )}`
                  : '#'
              }
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-flex items-center bg-green-500 text-white p-2 rounded-lg ${
                cliente ? 'hover:bg-green-600' : 'cursor-not-allowed'
              } transition duration-200 ml-2`}
              onClick={(e) => {
                if (!cliente) {
                  e.preventDefault();
                  alert('Los datos del cliente aún no están disponibles.');
                }
              }}
              >
              <FaWhatsapp className="mr-2" /> Enviar por Wsp
              </a>            
        </div>


          
          
            )}

            {orden.estado === 'pendiente_asignar' && (
              <>
                {isEditing ? (
                  <div className="mt-4">
                  <h3 className="text-lg font-semibold">Asignar Instalador</h3>

                  <div className="flex flex-col">
                    <label className="mt-2">Vehículo:</label>
                    <select
                      className="border p-2 rounded-lg"
                      value={selectedInstalador}
                      onChange={(e) => setSelectedInstalador(e.target.value)}
                    >
                      <option value="">Seleccione un vehículo</option>
                      {vehiculos.map((vehiculo) => (
                        <option key={vehiculo.id} value={vehiculo.nombre}>
                          {vehiculo.nombre}
                        </option>
                      ))}
                    </select>

                    <label className="mt-2">Fecha de instalación:</label>
                    <DatePicker
                      className="border p-2 rounded-lg"
                      selected={fechaInstalacion}
                      onChange={(date) => setFechaInstalacion(date)}
                      minDate={new Date()}
                      placeholderText="Seleccione una fecha"
                      dateFormat="dd/MM/yyyy"
                    />

                    <label className="mt-2">Rango horario:</label>
                    <select
                      className="border p-2 rounded-lg"
                      value={rangoHoras}
                      onChange={(e) => setRangoHoras(e.target.value)}
                    >
                      <option value="">Seleccione un rango de horas</option>
                      {horariosDisponibles
                        .filter((horario) => horario.disponible)
                        .map((horario) => (
                          <option key={horario.value} value={horario.value}>
                            {horario.label}
                          </option>
                        ))}
                    </select>

                      {/* Botones de Guardar y Cancelar */}
                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                          onClick={handleGuardarClick}
                        >
                          Guardar
                        </button>
                        <button
                          type="button"
                          className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                          onClick={() => setIsEditing(false)} // Volver a modo sin edición
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Botón Asignar */}
                    <button
                      type="button"
                      className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                      onClick={handleAsignarClick}
                    >
                      Asignar
                    </button>
                    <button
                      type="button"
                      className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                      onClick={() => handleChangeEstado('rechazado')}
                    >
                      Rechazar
                    </button>
                  </>
                )}
              </>
            )}

              {orden.estado === 'por_enviar' && (
              <>
                {isEditing ? (
                  <div className="mt-4">
                  <h3 className="text-lg font-semibold">Número seguimiento</h3>

                  <div className="flex flex-col">
                  <label className="mt-2">N° Seguimiento:</label>
                    <input
                      type="text"
                      className="border p-2 rounded-lg"
                      placeholder="Ingrese seguimiento"
                      value={numeroSeguimiento}
                      onChange={(e) => setNumeroSeguimiento(e.target.value)}
                    />
                  
                      {/* Botones de Guardar y Cancelar */}
                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                          onClick={handleGuardarClick}
                        >
                          Guardar
                        </button>
                        <button
                          type="button"
                          className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                          onClick={() => setIsEditing(false)} // Volver a modo sin edición
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Botón Asignar */}
                    <button
                      type="button"
                      className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                      onClick={handleAsignarClick}
                    >
                      Enviar
                    </button>
                    <button
                      type="button"
                      className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                      onClick={() => handleChangeEstado('rechazado')}
                    >
                      Rechazar
                    </button>
                  </>
                )}
              </>
            )}


            
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleOrden;
