import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ message = 'Procesando...' }) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <div className="loader"></div>
        <p className="overlay-message">{message}</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;